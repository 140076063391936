import slug from "slugify"
import { AssociationFilters, AssociationFilterType } from "../constants/filters"
import { inferAssociationFilterType } from "./inferAssociationFilterType"

export const slugify = (value: string) =>
  slug(value, {
    lower: true,
    strict: true,
    // Prevent symbols to create an extra word.
    // e.g. we want to avoid "Tennis pour tous !" => "tennis-pour-tous-".
    remove: /[!?]/g,
  })

export const getActivityPath = (activity: Activity) => `/activite/${slugify(activity.id)}/${slugify(activity.title)}/`

export const getMissionPath = (mission: Mission) => `/mission/${slugify(mission.id)}/${slugify(mission.title)}/`

const getAssociationPathBase = (association: Association) => {
  return `/association/${slugify(association.id)}`
}

export const getAssociationPath = (association: Association, filters?: AssociationFilters) => {
  const params = []
  const filterType = filters ? inferAssociationFilterType(filters) : AssociationFilterType.Activity
  if (filters) {
    const {
      ageIds,
      dayIds,
      scheduleIds,
      tagIds,
      activityIds,
      missionIds,
      missionCauseIds,
      missionSkillIds,
      missionRecurrences,
    } = filters
    if (filterType !== AssociationFilterType.Activity) {
      params.push("type=" + filterType)
    }
    if (ageIds?.length) {
      params.push("ages=" + ageIds.join(","))
    }
    if (dayIds?.length) {
      params.push("days=" + dayIds.join(","))
    }
    if (scheduleIds?.length) {
      params.push("schedules=" + scheduleIds.join(","))
    }
    if (tagIds) {
      params.push("tags=" + tagIds.join(","))
    }
    if (activityIds?.length) {
      params.push("activities=" + activityIds.join(","))
    }
    if (missionIds?.length) {
      params.push("missions=" + missionIds.join(","))
    }
    if (missionCauseIds?.length) {
      params.push("mission_causes=" + missionCauseIds.join(","))
    }
    if (missionSkillIds?.length) {
      params.push("mission_skills=" + missionSkillIds.join(","))
    }
    if (missionRecurrences?.length) {
      params.push("mission_recurrences=" + missionRecurrences.join(","))
    }
  }
  if (association.absolute != null) {
    params.push("exact=" + Number(association.absolute))
  }
  const path = `${getAssociationPathBase(association)}/${slugify(association.name)}/`

  if (params.length) {
    return `${path}?${params.join("&")}`
  }

  return path
}

export const getAssociationMatchPath = (association: Association) => {
  return `${getAssociationPathBase(association)}/*`
}

export const getHighlightPath = (highlight: DomainHighlight) => `/selection/${slugify(highlight.name)}/`

export const getSearchPath = (filters?: AssociationFilters) => {
  const params = []
  if (filters) {
    const { type, ageIds, dayIds, scheduleIds, missionRecurrences, tagIds, missionCauseIds, missionSkillIds } = filters
    if (type !== undefined && type !== AssociationFilterType.Activity) {
      // Default is `activity`.
      params.push("type=" + type)
    }
    if (tagIds?.length) {
      params.push("tags=" + tagIds.join(","))
    }
    if (ageIds?.length) {
      params.push("ages=" + ageIds.join(","))
    }
    if (ageIds?.length) {
      params.push("ages=" + ageIds.join(","))
    }
    if (dayIds?.length) {
      params.push("days=" + dayIds.join(","))
    }
    if (scheduleIds?.length) {
      params.push("schedules=" + scheduleIds.join(","))
    }
    if (missionRecurrences?.length) {
      params.push("mission_recurrences=" + missionRecurrences.join(","))
    }
    if (missionCauseIds?.length) {
      params.push("mission_causes=" + missionCauseIds.join(","))
    }
    if (missionSkillIds?.length) {
      params.push("mission_skills=" + missionSkillIds.join(","))
    }
  }
  if (params.length) {
    return "/search/?" + params.join("&")
  }
  return "/search/"
}

export const getStaticTagPath = (tag: Tag) => `/tag/${slugify(tag.name)}/`

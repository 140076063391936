import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { SIZES } from "src/constants"
import styled, { css, DefaultTheme, ThemeProps } from "styled-components"

const sizes = {
  small: "4px 14px",
  normal: "9px 20px",
}

interface ButtonProps {
  $size?: "small" | "normal"
  $empty?: boolean
}

const baseButton = css<ButtonProps>`
  font-size: ${SIZES.buttons}px;
  border: 1px solid ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  border-radius: 30px;
  white-space: nowrap;
  display: inline-block;
  padding: ${({ $size }) => sizes[$size || "normal"]};
  ${({ $empty, theme }) =>
    $empty
      ? `
        background-color: transparent;
        color: ${theme.textColor};
        `
      : `
        background-color: ${theme.primaryColor};
        color: ${theme.primaryTextColor};
        `}
  &:hover {
    background-color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryHighlightedColor};
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryTextHighlightedColor};
    border-color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryHighlightedColor};
  }
`

export const Button = styled.button<ButtonProps>`
  ${baseButton}
`
export const ButtonLink = styled(Link)<ButtonProps>`
  ${baseButton}
`
export const ButtonUrl = styled(OutboundLink)<ButtonProps>`
  ${baseButton}
`
export const IconButton = styled(Link)<ButtonProps>`
  ${baseButton}
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

import { AssociationFilters, AssociationFilterType } from "../constants/filters"

export const inferAssociationFilterType = (args: AssociationFilters): AssociationFilterType => {
  if (args.type) {
    return args.type
  }
  if (args.missionIds || args.missionRecurrences || args.missionCauseIds || args.missionSkillIds) {
    return AssociationFilterType.Mission
  }
  return AssociationFilterType.Activity
}

import { DAYS } from "src/constants/filters"

// https://medium.com/dailyjs/typescript-create-a-condition-based-subset-types-9d902cea5b8c
type FilterFlags<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}
type Filtered<Base, Condition> = FilterFlags<Base, Condition>[keyof Base]

export const trimmer = <T extends Record<string, any>>(obj: T, keys: Filtered<T, string>[]) =>
  keys.map((key) => ((obj[key] as string) || "").trim())

export const capitalize = (str: string) => str[0].toUpperCase() + str.substr(1)

export const getDayName = (id: string) => DAYS.options.find((option) => option.id === id)?.name

export const formatSchedule = ({ day_name, start_time, end_time, name }: ActivitySchedule) => {
  let text = getDayName(day_name)
  if (start_time) {
    text += " : " + start_time
    if (end_time) {
      text += " – " + end_time
    }
  }
  if (name) {
    text += " (" + name + ")"
  }
  return text
}

export const formatAge = (min?: number, max?: number) => {
  if (min > 0 && max && max < 90) {
    return `De ${min} à ${max} ans`
  }
  if (min > 0) {
    return `À partir de ${min} ans`
  }
  if (max && max < 90) {
    return `Jusqu’à ${max} ans`
  }
}

export interface Option<IDType = ID> {
  id: IDType
  name: string
}
export interface FilterData<IDType = ID> {
  title?: string
  options: Option<IDType>[]
}

export const DAYS: FilterData = {
  title: "Jour(s) de la semaine",
  options: [
    { name: "Lundi", id: "MONDAY" },
    { name: "Mardi", id: "TUESDAY" },
    { name: "Mercredi", id: "WEDNESDAY" },
    { name: "Jeudi", id: "THURSDAY" },
    { name: "Vendredi", id: "FRIDAY" },
    { name: "Samedi", id: "SATURDAY" },
    { name: "Dimanche", id: "SUNDAY" },
  ],
}

export enum MissionRecurrenceType {
  Punctual = "Punctual",
  Recurrent = "Recurrent",
}

export const MISSION_RECURRENCE: FilterData<MissionRecurrenceType> = {
  title: "",
  options: [
    { name: "Mission permanente", id: MissionRecurrenceType.Recurrent },
    { name: "Mission ponctuelle", id: MissionRecurrenceType.Punctual },
  ],
}

export enum AssociationFilterType {
  Association = "Association",
  Activity = "Activity",
  Mission = "Mission",
}

export interface AssociationFilters {
  type?: AssociationFilterType
  tagId?: ID
  tagIds?: ID[]
  ageIds?: ID[]
  dayIds?: ID[]
  scheduleIds?: ID[]
  activityIds?: ID[]
  missionIds?: ID[]
  missionRecurrences?: MissionRecurrenceType[]
  missionCauseIds?: ID[]
  missionSkillIds?: ID[]
}

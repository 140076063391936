import { Link } from "gatsby"
import { ButtonLink, IconButton } from "src/components/Button"
import { clamp } from "src/components/Clamp"
import Image from "src/components/Image"
import { COLORS, LAYOUT } from "src/constants"
import { AssociationFilters, AssociationFilterType } from "src/constants/filters"
import { inferAssociationFilterType } from "src/helpers/inferAssociationFilterType"
import { getAssociationPath, getSearchPath } from "src/helpers/slug"
import { capitalize } from "src/helpers/text"
import ChevronIcon from "src/svg/chevron.svg"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Container = styled.article`
  min-width: 280px;
  padding: 15px 20px 60px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  background-color: ${COLORS.white};
  position: relative;

  @media (min-width: ${LAYOUT.tablet}px) {
    height: 230px;
  }
  h1,
  h2,
  h3 {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  }
`
const Header = styled(Link)`
  background-color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const Logo = styled(Link)<{ $visible: boolean }>`
  position: relative;
  ${({ $visible }) => ($visible ? "" : "visibility: hidden;")}
  width: 60px;
  height: 60px;
  display: inline-block;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.bright};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
`
const SubEntities = styled.div`
  position: absolute;
  right: 20px;
  top: 62px;
`
const Title = styled.h3`
  ${clamp}
`
const Description = styled.p`
  ${clamp}
  margin: 5px 0 15px;
`
const Bottom = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface Props extends AssociationFilters {
  association: Association
}

const AssociationCard: React.FC<Props> = ({ association, ...filters }) => {
  const path = getAssociationPath(association, filters)
  const { id, header_image, header_url, logo_url, logo_image, name, description_text, main_tag } = association
  const activityCount = association.activities?.length
  const missionCount = association.missions?.length
  const filterType = inferAssociationFilterType(filters)

  return (
    <Container>
      <Header to={path} aria-labelledby={id}>
        <Image node={header_image} src={header_url} alt="" />
      </Header>
      <Logo to={path} $visible={!!logo_url} aria-labelledby={id}>
        <Image node={logo_image} src={logo_url} alt="" />
      </Logo>
      {filterType === AssociationFilterType.Activity && activityCount > 0 && (
        <SubEntities>
          {activityCount} activité{activityCount > 1 ? "s" : ""}
        </SubEntities>
      )}
      {filterType === AssociationFilterType.Mission && missionCount > 0 && (
        <SubEntities>
          {missionCount} mission{missionCount > 1 ? "s" : ""}
        </SubEntities>
      )}
      <Link to={path}>
        <Title $lines={1} id={id}>
          {name}
        </Title>
      </Link>
      <Description $lines={3}>{description_text}</Description>
      <Bottom>
        <ButtonLink $empty to={getSearchPath({ tagIds: [main_tag.id] })}>
          {capitalize(main_tag.name)}
        </ButtonLink>
        <IconButton to={path} aria-labelledby={id}>
          <ChevronIcon />
        </IconButton>
      </Bottom>
    </Container>
  )
}

export default AssociationCard
